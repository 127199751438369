<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown"
    class="px-10"
  >
    <v-row>
      <v-col>
        <h1 class="mt-5">ピックアップ動画の管理</h1>
        <banner-hint>
          店舗紹介動画やイベント用動画を登録してホーム画面管理のピックアップ動画パーツに表示することができます。<br />
          また、ピックアップ動画にはキャストのプロフィール動画も指定できるので特定キャストを推したい際にも活用しましょう。
        </banner-hint>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <!-- ******************************************** -->
        <!-- ピックアップ動画選択 -->
        <!-- ******************************************** -->
        <v-card class="mb-10 py-7 px-6">
          <v-form ref="form-pickup">
            <v-sheet class="mb-7">
              <h2>ピックアップ設定中の動画</h2>
              <icon-info icon="lightbulb-on-outline" class="mt-3" :square="true">
                動画は2つまで、登録した店舗動画とキャスト用プロフィール動画から選べます。
              </icon-info>
            </v-sheet>

            <v-row>
              <v-col cols="6"
                v-for="video in pickedupVideos"
                :key="video.id"
              >
                <video
                  controls
                  :src="video.video_url"
                  :poster="video.poster_url"
                ></video>
              </v-col>
            </v-row>

            <!-- 選択リスト -->
            <v-sheet class="my-7">
              <v-select
                v-model="pickedupVideos"
                label="ピックアップ動画を選択する"
                prepend-inner-icon="mdi-video"
                multiple chips outlined
                hide-details
                :items="allVideos"
                return-object
                item-text="video_name"
                item-color="primary"
                required
                :rules="[valiRules.requiredArray]"
              ></v-select>
            </v-sheet>

            <!-- ボタン -->
            <div class="d-flex justify-end">
              <v-btn
                depressed small
                color="primary"
                @click="updatePickupVideo()"
              >更新</v-btn>
            </div>
          </v-form>
        </v-card>

        <!-- ******************************************** -->
        <!-- サイト動画一覧 -->
        <!-- ******************************************** -->
        <div class="mb-7">
          <v-btn
            depressed
            color="primary"
            @click="addSiteVideo()"
          >店舗動画を追加</v-btn>
        </div>

        <v-card v-if="!siteVideos.length" flat>
          <v-card-text>店舗動画が登録されていません。</v-card-text>
        </v-card>

        <v-row>
          <v-col
            cols="12"
            v-for="(video, index) in siteVideos"
            :key="video.video_id"
          >
            <v-card
              class="pt-7 pb-5 px-6"
              elevation="1"
            >
              <v-form :ref="'form-video-' + video.video_id">
                <!-- 動画名 -->
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      class="pt-2"
                      v-model="video.video_name"
                      label="動画タイトル"
                      :rules="[valiRules.required, valiRules.max20]"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mt-5">
                  <!-- プレビュー -->
                  <v-col cols="12" md="6">
                    <v-sheet class="mt-n5 mb-5">
                      <small><p>プレビュー</p></small>
                      <video
                        controls
                        :src="video.video_url"
                        :poster="video.poster_url == 'no image' ? '' : video.poster_url"
                      ></video>
                    </v-sheet>
                  </v-col>
                  <!-- ビデオアップロード -->
                  <v-col cols="12" md="6">
                    <v-sheet>
                      <v-file-input
                        v-model="updateVideos[video.video_id]"
                        accept="video/mp4, video/mov, video/wav"
                        outlined
                        chips
                        show-size
                        required
                        prepend-icon="mdi-video"
                        label="動画をアップロード"
                        hint="サイズ上限：10MB"
                        persistent-hint
                        :rules="video.create ? [valiRules.required, valiRules.video] : [valiRules.video]"
                      >
                        <template v-slot:selection="{ text }">
                          <v-chip
                            color="primary"
                            label small
                          >
                            {{ text }}
                          </v-chip>
                        </template>
                      </v-file-input>
                    </v-sheet>
                    <!-- ポスター -->
                    <!-- <v-sheet
                      class="mt-n5 mb-5"
                      v-if="video.poster_url"
                    >
                      <small>動画用サムネイル</small>
                      <v-img
                        max-height="200"
                        contain
                        :src="video.poster_url"
                      >
                      </v-img>
                    </v-sheet> -->
                    <v-sheet class="mt-5">
                      <v-file-input
                        v-model="updatePosters[video.video_id]"
                        accept="image/png, image/jpeg"
                        outlined
                        chips
                        show-size
                        required
                        prepend-icon="mdi-image"
                        label="アイキャッチ画像"
                        hint="サイズ上限：1MB"
                        persistent-hint
                        :rules="[valiRules.image]"
                      >
                        <template v-slot:selection="{ text }">
                          <v-chip
                            color="primary"
                            label small
                          >
                            {{ text }}
                          </v-chip>
                        </template>
                      </v-file-input>
                    </v-sheet>
                  </v-col>
                </v-row>

                <!-- ボタン -->
                <v-row no-gutters
                  class="mt-3 justify-end"
                >
                  <div>
                    <v-btn
                      v-if="video.create"
                      depressed small
                      color="primary"
                      @click="createRow(video)"
                    >登録</v-btn>
                    <v-btn
                      v-else
                      depressed small
                      color="primary"
                      @click="updateRow(video)"
                    >更新</v-btn>
                    <v-btn
                      class="ml-2"
                      text
                      depressed small
                      color="primary"
                      @click="deleteRow(index)"
                    >削除</v-btn>
                  </div>
                </v-row>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import $literals from '@/literals.js'
import { SITE_API_ENDPOINT } from '@/literals.js'
import { ApiTool, CheckTokenError, ValidationRules } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import BannerHint from "@/components/_BannerHint.vue";
import IconInfo from "@/components/_IconInfo.vue";

export default {
  components: {
    'loader': Loader,
    'banner-hint': BannerHint,
    'icon-info': IconInfo,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      casts: [],
      castVideos: [],
      pickedupVideos: [],
      maxSiteVideoId: 0,
      siteVideos: [],
      updateVideos: [],
      updatePosters: [],
      loading: false,
      loadingMessage: '',
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
      publicApi: new ApiTool(SITE_API_ENDPOINT + '/', this.shopData),
    }
  },

  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
    allVideos() {
      return [...this.siteVideos, ...this.castVideos]
    }
  },

  watch: {
    pickedupVideos: {
      handler: function(now) {
        if (now.length > 2) this.pickedupVideos.shift()
      },
      deep: true
    },
  },

  created() {
    this.adminApi.setToken(this.serverToken)

    this.getData()
    .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
  },

  methods: {
    async getData() {
      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderLoading

      await this.adminApi.getReqWithAuth("cast/").then( results => {
        if (!results || !results.length) return
        this.casts = results
      })

      await Promise.all([
        this.setCastVideos(),
        this.setSiteVideos(),
      ])

      await this.getPickupVideo()

      if (!this.siteVideos.length && !this.castVideos.length) {
        this.snackbar = {...{color:'info', message: '店舗動画・プロフィール動画が登録さていません', open: true}}
      }

      this.loading = false
    },

    //新規追加
    addSiteVideo() {
      if (this.siteVideos.length >= 5) {
        this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoMaxedupRows, open: true}}
        return
      }

      this.siteVideos.unshift({
        video_id: ++this.maxSiteVideoId,
        video_name: '',
        video_url: '',
        poster_url: '',
        create: true
      })
    },

    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //
    // API req: GET
    //
    getPickupVideo() {
      return this.publicApi.getReqSitePublic('common/pickup-video/').then( results => {
        if (!results || !results.length) return

        //動画データを紐付け
        results.map( pickup => {
          const target = this.allVideos.find( video => {
            return video.video_id === pickup.video_id && video.cast_id === pickup.cast_id
          })

          if (target !== undefined) {
            const id = String(pickup.video_id) + String(pickup.cast_id)
            this.pickedupVideos.push({id: id, ...target})
          }
        })
      })
    },

    //
    //サイト動画のセット
    //
    setSiteVideos() {
      return this.publicApi.getReqSitePublic('common/site-video/').then( results => {
        if (!results || !results.length) return

        //id最大値の取得
        this.maxSiteVideoId = Math.max(...results.map( row => parseInt(row.video_id) ))

        results.map( (video, index) => {
          video.cast_id = 0
          video.video_name = video.video_name + '（店舗動画' + (index + 1) + '）'
          this.siteVideos.push(video)
        })
      })
    },

    //
    //キャスト動画のセット
    //
    setCastVideos() {
      return this.publicApi.getReqSitePublic('common/cast-video/').then( results => {
        if (!results || !results.length) return

        results.map( (video, index) => {
          this.casts.map( cast => {
            if (cast.cast_id === video.cast_id) {
              video.video_name = cast.name + '（プロフィール動画' + (index + 1) + '）'
            }
          })
          this.castVideos.push(video)
        })
      })
    },

    //**************************************************
    //ピックアップ動画更新
    //**************************************************
    updatePickupVideo() {
      if (!this.$refs['form-pickup'].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      const updateData = []
      this.pickedupVideos.map( video => {
        updateData.push({video_id: video.video_id, cast_id: video.cast_id || 0})
      })

      const payload = JSON.stringify(updateData)

      this.adminApi.apiReqWithData('PUT', 'update/site-pickup-video/', payload).then(() => {
        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successCreateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },

    //**************************************************
    //新規登録
    //**************************************************
    createRow(video) {
      if (!video.video_name || !this.updateVideos[video.video_id] ||
          !this.$refs['form-video-' + video.video_id][0].validate()
      ) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderInTarnsaction

      const formData = new FormData();
      formData.append('video_name', video.video_name)
      formData.append('video_url', this.updateVideos[video.video_id])
      formData.append('poster_url', this.updatePosters[video.video_id])

      this.adminApi.apiReqWithData('POST', 'create/site-video/', formData).then( response => {
        delete this.updateVideos[video.video_id]
        delete this.updatePosters[video.video_id]
        video.video_id = response.video_id
        video.video_url = response.video_url
        video.poster_url = response.poster_url
        video.create = false

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successCreateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //更新
    //**************************************************
    updateRow(video) {
      if (!this.$refs['form-video-' + video.video_id][0].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      const apiPath = 'update/site-video/' + video.video_id
      const formData = new FormData();
      formData.append('video_id', video.video_id)
      formData.append('video_name', video.video_name)
      formData.append('video_url', this.updateVideos[video.video_id] || video.video_url)
      formData.append('poster_url', this.updatePosters[video.video_id] || video.poster_url || 'no image')

      this.adminApi.apiReqWithData('PUT', apiPath, formData).then( response => {
        if (response.NoRowsAffected) {
          this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoNoRowsAffected, open: true}}
          return
        }

        delete this.updateVideos[video.video_id]
        delete this.updatePosters[video.video_id]
        video.video_url = response.video_url
        video.poster_url = response.poster_url
        video.create = false

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },

    //**************************************************
    //削除
    //**************************************************
    deleteRow(index) {
      if (this.siteVideos[index].create) {
        this.siteVideos.splice(index, 1)
        return
      }

      const apiPath = 'delete/site-video/' + this.siteVideos[index].video_id

      this.adminApi.apiReqWithData('DELETE', apiPath).then(() => {
        this.siteVideos.splice(index, 1)

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successDeleteSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    }
  }
}
</script>

<style scoped>
video {
  width: 100%;
  max-width: 400px;
}
</style>
